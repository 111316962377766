import Tinymce from "vue-tinymce-editor";

export default {
	components: {
		Tinymce
	},
	data() {
		return {
			blLoading: true,
			blSalvando: false,
			dados: null,
			obOptions: {
				language_url: process.env.VUE_APP_ROOT_API + "../langs-tinymce/pt_BR.js",
				//images_upload_url: process.env.VUE_APP_ROOT_API + "post/uploadImagem",
				convert_urls: false
			}
		}
	},
	created() {
		this.obter();
	},
	methods: {
		obter() {
			this.$root.$api.get('home/obter').then(
				response => {
					this.dados = response.retorno;
					this.blLoading = false;
				}
			)
		},
		introducaoInit() {
			this.$refs.refTinymceIntroducao.editor.setContent(this.dados.introducao_texto);
		},
		itemInit(index) {
			this.$refs['refTinymceItem' + index][0].editor.setContent(this.dados.itens[index].texto);
		},
		checkInit(index) {
			this.$refs['refTinymceCheck' + index][0].editor.setContent(this.dados.checks[index].texto);
		},
		adicionarItem() {
			let item = {
				id: 0,
				titulo: "",
				texto: ""
			}

			this.dados.itens.push(item);
		},
		excluirItem(id, index) {
			this.dados.itens.splice(index, 1);

			if (id != 0) {
				this.$root.$api.delete('home/excluirItem/' + id).then();
			}
		},
		adicionarCheck() {
			let check = {
				id: 0,
				texto: ""
			}

			this.dados.checks.push(check);
		},
		excluirCheck(id, index) {
			this.dados.checks.splice(index, 1);

			if (id != 0) {
				this.$root.$api.delete('home/excluirCheck/' + id).then();
			}
		},
		submit() {
			this.blSalvando = true;

			this.$root.$api.post('home/salvar', this.dados).then(
				response => {
					this.dados = response.retorno;
					this.blSalvando = false;
				}
			)
		}
	},
}